<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/bonuses`"
        />
        <div class="pageSection">
            <div class="pageContent pageContent--4">
                <BonusWebinarsList :items="purchasedCardItems" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import BonusWebinarsList from "@/views/components/BonusWebinarsList/BonusWebinarsList";

import PauseIcon from "@/assets/img/common/pause.svg";
import DoneIcon from "@/assets/img/common/iconCheckSuccess.svg";
import axios from "axios";

export default {
    name: "bonusWebinars",

    components: {
        Layout,
        PageHeader,
        BonusWebinarsList,
    },

    async beforeMount() {
        const $this = this;
        await $this.updateBonusData().then(async (updateBonusDataResult) => {
            if (updateBonusDataResult) {
                $this.renderPageData();
            } else {
                await this.$store.dispatch("clearBonus");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке бонуса",
                    },
                });
            }
        });
    },

    data() {
        return {
            title: "",
            items: [
                {
                    text: "Бонусы",
                    to: `/${this.$route.params.slug}/bonuses`,
                },
                {
                    text: "",
                    active: true,
                },
            ],

            viewAll: true,

            bonus: {},
            purchasedCardItems: [],
        };
    },
    methods: {
        renderPageData: function() {
            this.title = this.$store.getters.getBonus.category.title;
            this.items[1].text = this.$store.getters.getBonus.category.title;
            this.bonus = this.$store.getters.getBonus.category;

            if (this.bonus.materials && this.bonus.materials.length > 0) {
                for (const [index, material] of this.bonus.materials.entries()) {
                    const itemToPush = {
                        cardImg:
                            material.video_preview.includes("http") ||
                            material.video_preview.includes("https")
                                ? material.video_preview
                                : process.env.VUE_APP_OLD_CLIENT_TEST_URL.substring(
                                      0,
                                      process.env.VUE_APP_OLD_CLIENT_TEST_URL.length - 1
                                  ) + material.video_preview,
                        cardTitle: material.name,
                        cardRoute: `/${this.$route.params.slug}/bonuses/watch/${this.$route.params.id}/video/${index}`,
                        cardDescription: material.video_description,
                        cardCourator: material.video_lector,
                        buttonTitle: "смотреть",
                    };

                    if (material.video_status && material.video_status === "done") {
                        itemToPush.cardProgress = "Просмотрено";
                        itemToPush.cardProgressIcon = DoneIcon;
                    } else if (
                        material.video_status &&
                        material.video_status === "inprogress"
                    ) {
                        itemToPush.cardProgress = "В процессе";
                        itemToPush.cardProgressIcon = PauseIcon;
                    }

                    this.purchasedCardItems.push(itemToPush);
                }
            }
        },

        updateBonusData: async function() {
            const $this = this;
            const getBonusDataResult = await axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/bonuses/" +
                    this.$route.params.id
            );
            if (
                getBonusDataResult &&
                getBonusDataResult.data &&
                getBonusDataResult.data.result
            ) {
                return await $this.$store
                    .dispatch("setBonus", getBonusDataResult.data)
                    .then(() => {
                        return true;
                    });
            } else {
                return true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "bonusWebinars.scss";
</style>
