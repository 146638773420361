<template>
    <div class="bonusWebinarsList">
        <div class="bonusWebinarsList__content">
            <div
                class="bonusWebinarsList__contentItems"
                v-for="item in items"
                :key="item.cardTitle"
            >
                <BonusVideoCard
                    :cardImg="item.cardImg"
                    :cardTitle="item.cardTitle"
                    :cardDescription="item.cardDescription"
                    :cardCourator="item.cardCourator"
                    :cardRoute="item.cardRoute"
                    :buttonTitle="item.buttonTitle"
                    :cardProgress="item.cardProgress"
                    :cardProgressIcon="item.cardProgressIcon"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BonusVideoCard from "@/views/components/BonusVideoCard/BonusVideoCard";

export default {
    name: "BonusWebinarsList",

    props: {
        items: {
            cardImg: String,
            cardTitle: String,
            cardDescription: String,
            cardCourator: String,
            cardRoute: String,
            buttonTitle: String,
            cardProgress: String,
            cardProgressIcon: String,
        }
    },

    components: {
        BonusVideoCard,
    },
}
</script>

<style lang="scss" scoped>
@import "BonusWebinarsList.scss";
</style>