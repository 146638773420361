<template>
    <div class="bonusVideoCard" v-if="cardTitle">
        <img class="bonusVideoCard__img" v-if="cardImg" v-bind:src="cardImg" />
        <div class="bonusVideoCard__content">
            <div class="bonusVideoCard__contentItem">
                <p class="h3" v-if="cardTitle">
                    {{ cardTitle }}
                </p>
                <p class="colorGray bonusVideoCard__description" v-if="cardDescription">
                    {{ cardDescription }}
                </p>
                <p class="colorFiord bonusVideoCard__courator" v-if="cardCourator">
                    {{ cardCourator }}
                </p>
            </div>
            <div class="bonusVideoCard__footer">
                <router-link v-if="cardRoute" v-bind:to="cardRoute">
                    <Button
                        class="bonusVideoCard__button"
                        v-if="buttonTitle"
                        v-bind:title="buttonTitle"
                        :onClick="() => anonymousClick()"
                    />
                </router-link>
                <div
                    class="bonusVideoCard__progress"
                    v-if="cardProgress && cardProgressIcon"
                >
                    <p>{{ cardProgress }}</p>
                    <img class="bonusVideoCard__icon" v-bind:src="cardProgressIcon" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "bonusWebinarsCard",

    components: {
        Button,
    },

    props: {
        cardImg: String,
        cardTitle: String,
        cardDescription: String,
        cardCourator: String,
        cardRoute: String,
        buttonTitle: String,
        cardProgress: String,
        cardProgressIcon: String,
    },

    methods: {
        anonymousClick() {

        },
    },
};
</script>

<style lang="scss">
@import "./BonusVideoCard.scss";
</style>
